define('m13/controllers/system', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    openFromFile: false,
    displayErrors: true,
    i18n: Ember['default'].inject.service(),

    einschraubtiefeKopfDisabled: true,
    einschraubwinkelDefiniertDisabled: true,
    schraubenabstandDefiniertDisabled: true,

    ttEinschraubtiefeKopf: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "2.0 - 9999.9 [cm]";
    }),
    ttEinschraubwinkelDefiniert: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "2.0 - 9999.9 [cm]";
    }),
    ttSchraubenabstandDefiniert: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "2.0 - 9999.9 [cm]";
    }),

    ttReibung: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.0 - 0.4";
    }),

    validations: {
      reibung: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 0.4
        }
      }
    },

    einschraubtiefe: 2, //anpassen nach array!
    einschraubtiefeKopf: "0.0",
    einschraubwinkel: 90,
    einschraubwinkelDefiniert: "90.0",
    schraubenabstand: 0,
    schraubenabstandDefiniert: "0.0",
    einschraubrichtung: true,

    einschraubtiefen: [],
    eswinkel: [],
    sabstand: [],
    einschraubrichtungen: [],

    showFriction: false,

    init: function init() {
      var self = this;
      this._super();

      var applicationdata = this.controllerFor('application').get('model').application.objectAt(0);

      var lang = applicationdata.get('countrycode');
      var einschraubtiefen = "einschraubtiefen";

      self.setSelectFieldsContent();

      // this.set('einschraubtiefen', einschraubtiefenDB.einschraubtiefen_de);
      this.set('einschraubtiefe', 2);

      // this.set('eswinkel', einschraubwinkelDB.einschraubwinkel_de);
      this.set('einschraubwinkel', 90);

      // this.set('sabstand', schraubenabstandDB.schraubenabstand_de);
      this.set('schraubenabstand', 0);

      // this.set('einschraubrichtungen', einschraubrichtungDB.einschraubrichtung_de);
      this.set('einschraubrichtung', true);
    },

    setValues: function setValues(values) {
      if (self.debug) {
        console.log("values in system: " + values);
      }

      this.set('openFromFile', true);

      this.set('einschraubtiefeKopf', values.sFastener);
      this.set('einschraubtiefe', parseInt(values.vmPosition));

      // this.set('einschraubwinkelDefiniert', );
      // this.set('schraubenabstandDefiniert', parseInt(values.e0_VM));
      this.set('einschraubwinkel', Number(values.AngleGRAD_Fasterner));
      this.set('reibung', values.reibbeiwert);

      this.set('schraubenabstand', parseInt(values.Var_sVM));

      this.set('reibungBeruecksichtigen', values.ETA_friction);

      this.set('einschraubrichtung', Boolean(values.einschraubrichtungVonOben));

      this.set('einschraubwinkelDefiniert', values.AngleGRAD_Fasterner);

      this.send('validation');
      this.setScrewproperties();

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setEinschraubtiefen();
      self.setEswinkel();
      self.setSabstand();
      self.setEinschraubrichtungen();
    },

    setEinschraubtiefen: function setEinschraubtiefen() {
      var self = this;
      var indices = [0, 1, 2];
      self.set('einschraubtiefen', self.getSelectFieldContent('einschraubtiefen', indices));
    },

    setEswinkel: function setEswinkel() {
      var self = this;
      var indices = [45, 90, 2];
      self.set('eswinkel', self.getSelectFieldContent('eswinkel', indices));
    },

    setSabstand: function setSabstand() {
      var self = this;
      var indices = [0, 1];
      self.set('sabstand', self.getSelectFieldContent('sabstand', indices));
    },

    setEinschraubrichtungen: function setEinschraubrichtungen() {
      var self = this;
      var indices = [true, false];
      self.set('einschraubrichtungen', self.getSelectFieldContent('einschraubrichtungen', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].value === indexes[i]) {
            tmp.push({ value: dbContent[k].value, name: this.get('i18n').t(dbContent[k].name), disabled: dbContent[k].disabled });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'einschraubtiefen':
          dbContent = einschraubtiefenDB.einschraubtiefen_de;
          break;
        case 'eswinkel':
          dbContent = einschraubwinkelDB.einschraubwinkel_de;
          break;
        case 'sabstand':
          dbContent = schraubenabstandDB.schraubenabstand_de;
          break;
        case 'einschraubrichtungen':
          dbContent = einschraubrichtungDB.einschraubrichtung_de;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    reibungBeruecksichtigen: false, //checkbox
    reibungBeruecksichtigenDisabled: true, //disable-var, disabled=mindestanzahlDisabled in {{ ... }}

    watchReibung: (function () {
      var self = this;

      if (self.get('reibungBeruecksichtigen')) {
        self.set('reibungBeruecksichtigenDisabled', false);
      } else {
        self.set('reibungBeruecksichtigenDisabled', true);
      }

      var systemdata = this.controllerFor('application').get('model').system.objectAt(0);
      systemdata.set('ETA_friction', self.get('reibungBeruecksichtigen'));
    }).observes('reibungBeruecksichtigen'),

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('reibung').indexOf(",") !== -1) {
          this.set('reibung', this.get('reibung').replace(",", "."));
          this.setfuehrendeNull('reibung');
        }
      }
    }).observes('reibung'),

    setfuehrendeNull: function setfuehrendeNull(ind) {
      if (this.get(ind).charAt(0) === '.') {
        this.set(ind, '0.');
      }
    },

    watchComboboxes: (function () {

      var self = this;

      self.controllerFor('supercontroller').resetApplication();

      var systemdata = this.controllerFor('application').get('model').system.objectAt(0);

      systemdata.set('vmPosition', this.get('einschraubtiefe'));
      systemdata.set('AngleGRAD_Fasterner', this.get('einschraubwinkel'));
      systemdata.set('Var_sVM', this.get('schraubenabstand'));
      systemdata.set('einschraubrichtungVonOben', this.get('einschraubrichtung'));
      systemdata.set('ETA_friction', self.get('reibungBeruecksichtigen'));

      self.set('einschraubwinkelDefiniert', Number(self.get('einschraubwinkel')).toFixed(1));

      if (parseFloat(self.get('einschraubwinkel')) != 90) {
        // vorübergehend deaktiviert
        // self.set('showFriction', true);
      } else {
          self.set('showFriction', false);
        }
    }).observes('einschraubtiefe', 'einschraubwinkel', 'schraubenabstand', 'einschraubrichtung'),

    setScrewproperties: (function () {

      var self = this;

      var geometrie = self.controllerFor('geometrie');
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var materialLaenge = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x');
      var deltaMaterialLaenge = 1;

      var hMaterial2 = Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y'));
      var hSchalung = Number(bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).get('y'));
      var hMaterial1 = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y'));
      var hLager = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y'));

      var einschraubwinkel = Number(this.get('einschraubwinkel'));
      var einschraubrichtung = this.get('einschraubrichtung');

      var hoehe = hMaterial1 / 2 + hSchalung + hMaterial2;
      var winkel = 0;
      var xPos = 0;
      var yPos = hLager + hMaterial1 / 2;

      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

      if (einschraubwinkel === 45 && einschraubrichtung === true) {
        winkel = 0.7854;
      } else if (einschraubwinkel === 90 && einschraubrichtung === true) {
        winkel = 0;
        deltaMaterialLaenge = materialLaenge / 5;
      } else if (einschraubwinkel === 45 && einschraubrichtung === false) {
        winkel = 3.927;
        yPos = hLager + hMaterial1 + hSchalung + hMaterial2 / 2;
        hoehe = hMaterial1 + hSchalung + hMaterial2 / 2;
        deltaMaterialLaenge = materialLaenge / 5;
      } else if (einschraubwinkel === 90 && einschraubrichtung === false) {
        winkel = 3.1416;
        yPos = hLager + hMaterial1 + hSchalung + hMaterial2 / 2;
        hoehe = hMaterial1 + hSchalung + hMaterial2 / 2;
        deltaMaterialLaenge = materialLaenge / 5;
      }

      bauteile.forEach(function (item) {
        if (item.get('typ') === 'standardSchraube') {

          var verschiebungsRichtung = Number(item.get('translations').objectAt(0).get('x')) / Math.abs(Number(item.get('translations').objectAt(0).get('x')));

          item.get('translations').objectAt(0).set('y', yPos);

          var winkelRichtung = -verschiebungsRichtung;
          item.get('rotations').objectAt(0).set('z', winkel * winkelRichtung);

          var schraubenLaenge = hoehe / Math.cos(winkel);

          item.get('screwProperties').objectAt(0).set('length', Math.abs(schraubenLaenge));
          item.get('screwProperties').objectAt(0).set('threadlengthtip', Math.abs(schraubenLaenge));
        }
      });

      bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).set('x', hoehe + 6);
      bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('x', hoehe + 6);
      bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).set('x', hoehe + 6);

      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    }).observes('einschraubrichtung', 'einschraubwinkel'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          //this.setX3D(value, event);

        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('systemInvalid', false);

            self.controllerFor('supercontroller').resetApplication();

            var systemdata = self.controllerFor('application').get('model').system.objectAt(0);

            systemdata.set('sFastener', parseFloat(self.get('einschraubtiefeKopf')).toFixed(1));
            systemdata.set('einschraubwinkelDefiniert', parseFloat(self.get('einschraubwinkelDefiniert')).toFixed(1));
            systemdata.set('e0_VM', parseFloat(self.get('schraubenabstandDefiniert')).toFixed(1));
            systemdata.set('AngleGRAD_Fasterner', parseFloat(self.get('einschraubwinkelDefiniert')).toFixed(1));
            systemdata.set('reibbeiwert', parseFloat(self.get('reibung')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('systemInvalid', true);
          }
        });
      }

    }

  });

});